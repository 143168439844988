
.main-section {
    &--expertise {
        overflow: hidden;
        ::v-deep {
            > .container {
                max-width: var(--container-lg);
                padding: 0;
            }
        }
        &__left,
        &__right {
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &__left {
            background-image: url(/images/main/expertise-bg-mo.jpg);
            padding: var(--page-section-padding-y) var(--container-gutter) 20px;
        }
        &__right {
            background-image: url(/images/main/expertise-bg-mo2.jpg);
        }
    }
    &--media {
        background-image: url(/images/main/media-bg.jpg);
        &__left {
            position: relative;
            z-index: 5;
        }
        .media-bg {
            position: absolute;
            left: 0;
            &--1 {
                bottom: 0;
            }
        }
    }
    &--introduction {
        background-image: url(/images/main/introduction-bg.jpg);
    }
    &--about {
        overflow: hidden;
        background-image: url(/images/main/about-bg.jpg);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section {
        &--expertise {
            ::v-deep {
                > .container {
                    max-width: calc(var(--container-lg) + var(--container-gutter) * 2);
                    padding: var(--container-gutter);
                }
            }
            background-image: url(/images/main/expertise-bg.jpg);
            &__left {
                padding: var(--page-section-padding-y) 0;
                background-image: none;
            }
            &__right {
                background-image: none;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
